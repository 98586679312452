export const paths = {
  templates: {
    new: (slug: string) => `/${slug}/templates/new`,
    edit: (slug: string, id: string) => `/${slug}/templates/${id}/edit`,
  },
  dataSources: {
    new: (slug: string) => `/${slug}/data_sources/new`,
    edit: (slug: string, id: string) => `/${slug}/data_sources/${id}/edit`,
    newStatic: (slug: string) => `/${slug}/static_data_sources/new`,
    editStatic: (slug: string, id: string) => `/${slug}/static_data_sources/${id}/edit`,
  },
  prospecting: {
    new: (slug: string) => `/${slug}/prospecting/new`,
    edit: (slug: string, id: string) => `/${slug}/prospecting/${id}/edit`,
    details: (slug: string, id: string) => `/${slug}/prospecting/${id}`,
  },
  dashboards: {
    clientOrHQ: (slug: string) => `/${slug}`,
    tenant: (id: string) => `/tenant/${id}`,
    user: (id: string) => `/user/${id}`,
  },
  pdfLibrary:{
    tenant:(id:string)=>`/tenants/${id}/pdfupload`
  },
  list: {
    clients: `/`,
    campaigns: `/campaigns`,
    videoTemplates: `/videoTemplates`,
    groups: `/groups`,
    users: `/users`,
    orders: `/orders`,
    resellers: `/resellers`,
    tenants: `/tenants`,
    dataSourceFailures: "/data_source_failures",
    userDrafts:"/userDrafts",
    telemetry: "/telemetry",
  },
  authentication: {
    login: "/login",
    unauthorized: "/401",
    permissiondenied: "/403"
  },
  link: {
    noAd:  `/no_ad`,
  }
}
